import { graphql } from 'gatsby'
import { gridMap } from '../utils'
import { LinkboxCarousel } from '../components/LinkboxCarousel'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { TourOperatorWidget, ExternalWidget } from '../components/Widgets'
import { NewsletterSubscribeBlock } from '../components/Newsletter'
import { PageLayout } from '../components/Layout'
import { Trustpilot } from '../components/Trustpilot'
import { PriceDisclaimer } from '../components/PriceDisclaimer'
import { PageContentTextSection } from '../components/PageContentTextSection'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import Hero from '../components/Hero/Hero'

const ProductCategoryPage = ({ data: { content } }) => {
  const {
    hero,
    covidBannerLayout,
    miniBookReference,
    termsAndConditionsReference,
    textAndMediaContentReference,
    sliderFerryToCountries,
    optionalSliderFerryToCountries2,
    sliderTravelGuides,
    optionalTextAndMediaContentReference,
    tourOperatorWidget,
    goldenHourTable,
    goldenHourMap,
    optionalNewsletterSubscribeBlock,
    trustpilotRef,
    additionalTrustpilotRef,
    faqSection,
    sys,
  } = content.page

  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} miniBookReference={miniBookReference} />

          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      {tourOperatorWidget && <TourOperatorWidget {...tourOperatorWidget} />}
      {goldenHourTable && <ExternalWidget {...goldenHourTable} />}
      {goldenHourMap && <ExternalWidget {...goldenHourMap} />}
      {tourOperatorWidget && termsAndConditionsReference && (
        <PageContentTextSection {...termsAndConditionsReference} />
      )}
      <TextAndMediaLayout
        reverse
        mediaWidth={gridMap['two-thirds']}
        title={textAndMediaContentReference.title}
        text={textAndMediaContentReference.text}
        images={textAndMediaContentReference.imagesCollection.items}
        links={textAndMediaContentReference.linksCollection.items}
        {...textAndMediaContentReference}
      />
      {!tourOperatorWidget && termsAndConditionsReference && (
        <PageContentTextSection {...termsAndConditionsReference} />
      )}
      {trustpilotRef && (
        <Trustpilot
          {...trustpilotRef}
          template="productCategoryPage"
          id={sys.id}
          item="trustpilotRef"
        />
      )}
      <LinkboxCarousel {...sliderFerryToCountries} />
      {optionalSliderFerryToCountries2 && (
        <LinkboxCarousel {...optionalSliderFerryToCountries2} />
      )}
      {sliderTravelGuides && <LinkboxCarousel {...sliderTravelGuides} />}
      {additionalTrustpilotRef && (
        <Trustpilot
          {...additionalTrustpilotRef}
          template="productCategoryPage"
          id={sys.id}
          item="additionalTrustpilotRef"
        />
      )}
      {optionalTextAndMediaContentReference && (
        <TextAndMediaLayout
          reverse
          mediaWidth={gridMap['two-thirds']}
          title={optionalTextAndMediaContentReference.title}
          text={optionalTextAndMediaContentReference.text}
          images={optionalTextAndMediaContentReference.imagesCollection.items}
          links={optionalTextAndMediaContentReference.linksCollection.items}
          {...optionalTextAndMediaContentReference}
        />
      )}
      {optionalNewsletterSubscribeBlock && (
        <NewsletterSubscribeBlock
          {...optionalNewsletterSubscribeBlock}
          reverse
        />
      )}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
      <PriceDisclaimer />
    </PageLayout>
  )
}

export const query = graphql`
  query ProductCategoryPage(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: productCategoryPage(id: $id, locale: $locale, preview: $preview) {
        sys {
          id
        }
        hero: heroReference(locale: $locale) {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        miniBookReference {
          ...Minibook
        }
        termsAndConditionsReference {
          ...PageContentTextSection
        }
        textAndMediaTitle
        textAndMediaContentReference {
          ...TextAndMediaContent
        }
        sliderFerryToCountries {
          ...LinkboxCarousel
        }
        optionalSliderFerryToCountries2 {
          ...LinkboxCarousel
        }
        sliderTravelGuides {
          ...LinkboxCarousel
        }
        optionalTextAndMediaContentReference {
          ...TextAndMediaContent
        }
        tourOperatorWidget {
          ...TourOperatorWidget
        }
        goldenHourTable {
          ...ExternalWidget
        }
        goldenHourMap {
          ...ExternalWidget
        }
        trustpilotRef {
          ...Trustpilot
        }
        additionalTrustpilotRef {
          ...Trustpilot
        }
        optionalNewsletterSubscribeBlock {
          ...NewsletterSubscribeBlock
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default ProductCategoryPage
